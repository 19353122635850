/* eslint-disable */
import * as Types from '../types.generated';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type GetMeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type GetMeQuery = { __typename?: 'Query' } & {
    me?: Types.Maybe<
        { __typename?: 'AuthenticatedUser' } & {
            corpUser: { __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'username'> & {
                    info?: Types.Maybe<
                        { __typename?: 'CorpUserInfo' } & Pick<
                            Types.CorpUserInfo,
                            'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName' | 'email'
                        >
                    >;
                    editableInfo?: Types.Maybe<
                        { __typename?: 'CorpUserEditableInfo' } & Pick<
                            Types.CorpUserEditableInfo,
                            'pictureLink' | 'teams' | 'skills'
                        >
                    >;
                };
            platformPrivileges: { __typename?: 'PlatformPrivileges' } & Pick<
                Types.PlatformPrivileges,
                'viewAnalytics' | 'managePolicies' | 'manageIdentities' | 'generatePersonalAccessTokens'
            >;
        }
    >;
};

export const GetMeDocument = gql`
    query getMe {
        me {
            corpUser {
                urn
                username
                info {
                    active
                    displayName
                    title
                    firstName
                    lastName
                    fullName
                    email
                }
                editableInfo {
                    pictureLink
                    teams
                    skills
                }
            }
            platformPrivileges {
                viewAnalytics
                managePolicies
                manageIdentities
                generatePersonalAccessTokens
            }
        }
    }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
    return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
    return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, baseOptions);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
