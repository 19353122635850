/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
} from './fragments.generated';
export type EntityPreview_Chart_Fragment = { __typename?: 'Chart' } & Pick<
    Types.Chart,
    'urn' | 'type' | 'tool' | 'chartId'
> & {
        properties?: Types.Maybe<
            { __typename?: 'ChartProperties' } & Pick<
                Types.ChartProperties,
                'name' | 'description' | 'externalUrl' | 'type' | 'access'
            > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'ChartEditableProperties' } & Pick<Types.ChartEditableProperties, 'description'>
        >;
    };

export type EntityPreview_CorpGroup_Fragment = { __typename?: 'CorpGroup' } & Pick<
    Types.CorpGroup,
    'name' | 'urn' | 'type'
> & {
        info?: Types.Maybe<{ __typename?: 'CorpGroupInfo' } & Pick<Types.CorpGroupInfo, 'displayName' | 'description'>>;
        relationships?: Types.Maybe<
            { __typename?: 'EntityRelationshipsResult' } & {
                relationships: Array<
                    { __typename?: 'EntityRelationship' } & Pick<Types.EntityRelationship, 'type' | 'direction'> & {
                            entity:
                                | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                                | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'username' | 'urn' | 'type'> & {
                                          info?: Types.Maybe<
                                              { __typename?: 'CorpUserInfo' } & Pick<
                                                  Types.CorpUserInfo,
                                                  'active' | 'displayName' | 'title' | 'firstName' | 'lastName'
                                              >
                                          >;
                                          editableInfo?: Types.Maybe<
                                              { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                  Types.CorpUserEditableInfo,
                                                  'pictureLink'
                                              >
                                          >;
                                      })
                                | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                                | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                                | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                                | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'urn' | 'type'>)
                                | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                                | ({ __typename?: 'MLFeatureTable' } & Pick<Types.MlFeatureTable, 'urn' | 'type'>)
                                | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                                | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                                | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                                | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>);
                        }
                >;
            }
        >;
    };

export type EntityPreview_CorpUser_Fragment = { __typename?: 'CorpUser' } & Pick<
    Types.CorpUser,
    'username' | 'urn' | 'type'
> & {
        info?: Types.Maybe<
            { __typename?: 'CorpUserInfo' } & Pick<
                Types.CorpUserInfo,
                'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName'
            >
        >;
        editableInfo?: Types.Maybe<
            { __typename?: 'CorpUserEditableInfo' } & Pick<Types.CorpUserEditableInfo, 'pictureLink'>
        >;
    };

export type EntityPreview_Dashboard_Fragment = { __typename?: 'Dashboard' } & Pick<
    Types.Dashboard,
    'urn' | 'type' | 'tool' | 'dashboardId'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DashboardProperties' } & Pick<
                Types.DashboardProperties,
                'name' | 'description' | 'externalUrl' | 'access'
            > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DashboardEditableProperties' } & Pick<Types.DashboardEditableProperties, 'description'>
        >;
    };

export type EntityPreview_DataFlow_Fragment = { __typename?: 'DataFlow' } & Pick<
    Types.DataFlow,
    'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
> & {
        properties?: Types.Maybe<
            { __typename?: 'DataFlowProperties' } & Pick<Types.DataFlowProperties, 'name' | 'description' | 'project'>
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataFlowEditableProperties' } & Pick<Types.DataFlowEditableProperties, 'description'>
        >;
    };

export type EntityPreview_DataJob_Fragment = { __typename?: 'DataJob' } & Pick<
    Types.DataJob,
    'urn' | 'type' | 'jobId'
> & {
        dataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment>;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        properties?: Types.Maybe<
            { __typename?: 'DataJobProperties' } & Pick<Types.DataJobProperties, 'name' | 'description'>
        >;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        editableProperties?: Types.Maybe<
            { __typename?: 'DataJobEditableProperties' } & Pick<Types.DataJobEditableProperties, 'description'>
        >;
    };

export type EntityPreview_DataPlatform_Fragment = { __typename?: 'DataPlatform' } & Pick<
    Types.DataPlatform,
    'name' | 'urn' | 'type'
> & {
        info?: Types.Maybe<
            { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'displayName' | 'logoUrl'>
        >;
    };

export type EntityPreview_Dataset_Fragment = { __typename?: 'Dataset' } & Pick<
    Types.Dataset,
    'name' | 'origin' | 'uri' | 'platformNativeType' | 'urn' | 'type'
> & {
        platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                info?: Types.Maybe<
                    { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'displayName' | 'logoUrl'>
                >;
            };
        editableProperties?: Types.Maybe<
            { __typename?: 'DatasetEditableProperties' } & Pick<Types.DatasetEditableProperties, 'description'>
        >;
        properties?: Types.Maybe<
            { __typename?: 'DatasetProperties' } & Pick<Types.DatasetProperties, 'description'> & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
        glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
        subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
    };

export type EntityPreview_GlossaryTerm_Fragment = { __typename?: 'GlossaryTerm' } & Pick<
    Types.GlossaryTerm,
    'name' | 'urn' | 'type'
> & {
        glossaryTermInfo?: Types.Maybe<
            { __typename?: 'GlossaryTermInfo' } & Pick<
                Types.GlossaryTermInfo,
                'definition' | 'termSource' | 'sourceRef' | 'sourceUrl'
            > & {
                    customProperties?: Types.Maybe<
                        Array<{ __typename?: 'StringMapEntry' } & Pick<Types.StringMapEntry, 'key' | 'value'>>
                    >;
                }
        >;
    };

export type EntityPreview_MlFeature_Fragment = { __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>;

export type EntityPreview_MlFeatureTable_Fragment = { __typename?: 'MLFeatureTable' } & Pick<
    Types.MlFeatureTable,
    'urn' | 'type' | 'name' | 'description'
> & {
        featureTableProperties?: Types.Maybe<
            { __typename?: 'MLFeatureTableProperties' } & Pick<Types.MlFeatureTableProperties, 'description'> & {
                    mlFeatures?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn'>>>
                    >;
                    mlPrimaryKeys?: Types.Maybe<
                        Array<Types.Maybe<{ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn'>>>
                    >;
                }
        >;
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
    };

export type EntityPreview_MlModel_Fragment = { __typename?: 'MLModel' } & Pick<
    Types.MlModel,
    'name' | 'description' | 'origin' | 'urn' | 'type'
> & {
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                info?: Types.Maybe<
                    { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'displayName' | 'logoUrl'>
                >;
            };
    };

export type EntityPreview_MlModelGroup_Fragment = { __typename?: 'MLModelGroup' } & Pick<
    Types.MlModelGroup,
    'name' | 'origin' | 'description' | 'urn' | 'type'
> & {
        ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
        platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                info?: Types.Maybe<
                    { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'displayName' | 'logoUrl'>
                >;
            };
    };

export type EntityPreview_MlPrimaryKey_Fragment = { __typename?: 'MLPrimaryKey' } & Pick<
    Types.MlPrimaryKey,
    'urn' | 'type'
>;

export type EntityPreview_Tag_Fragment = { __typename?: 'Tag' } & Pick<
    Types.Tag,
    'name' | 'description' | 'urn' | 'type'
>;

export type EntityPreviewFragment =
    | EntityPreview_Chart_Fragment
    | EntityPreview_CorpGroup_Fragment
    | EntityPreview_CorpUser_Fragment
    | EntityPreview_Dashboard_Fragment
    | EntityPreview_DataFlow_Fragment
    | EntityPreview_DataJob_Fragment
    | EntityPreview_DataPlatform_Fragment
    | EntityPreview_Dataset_Fragment
    | EntityPreview_GlossaryTerm_Fragment
    | EntityPreview_MlFeature_Fragment
    | EntityPreview_MlFeatureTable_Fragment
    | EntityPreview_MlModel_Fragment
    | EntityPreview_MlModelGroup_Fragment
    | EntityPreview_MlPrimaryKey_Fragment
    | EntityPreview_Tag_Fragment;

export const EntityPreviewFragmentDoc = gql`
    fragment entityPreview on Entity {
        urn
        type
        ... on Dataset {
            name
            origin
            uri
            platform {
                name
                info {
                    displayName
                    logoUrl
                }
            }
            editableProperties {
                description
            }
            platformNativeType
            properties {
                description
                customProperties {
                    key
                    value
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            subTypes {
                typeNames
            }
        }
        ... on CorpUser {
            username
            info {
                active
                displayName
                title
                firstName
                lastName
                fullName
            }
            editableInfo {
                pictureLink
            }
        }
        ... on CorpGroup {
            name
            info {
                displayName
                description
            }
            relationships(input: { types: ["IsMemberOfGroup"], direction: INCOMING }) {
                relationships {
                    type
                    direction
                    entity {
                        urn
                        type
                        ... on CorpUser {
                            username
                            info {
                                active
                                displayName
                                title
                                firstName
                                lastName
                            }
                            editableInfo {
                                pictureLink
                            }
                        }
                    }
                }
            }
        }
        ... on Dashboard {
            urn
            type
            tool
            dashboardId
            properties {
                name
                description
                externalUrl
                access
                lastModified {
                    time
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
        }
        ... on Chart {
            urn
            type
            tool
            chartId
            properties {
                name
                description
                externalUrl
                type
                access
                lastModified {
                    time
                }
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
        }
        ... on DataFlow {
            urn
            type
            orchestrator
            flowId
            cluster
            properties {
                name
                description
                project
            }
            ownership {
                ...ownershipFields
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
        }
        ... on DataJob {
            urn
            type
            dataFlow {
                ...nonRecursiveDataFlowFields
            }
            jobId
            ownership {
                ...ownershipFields
            }
            properties {
                name
                description
            }
            globalTags {
                ...globalTagsFields
            }
            glossaryTerms {
                ...glossaryTerms
            }
            editableProperties {
                description
            }
        }
        ... on GlossaryTerm {
            name
            glossaryTermInfo {
                definition
                termSource
                sourceRef
                sourceUrl
                customProperties {
                    key
                    value
                }
            }
        }
        ... on MLFeatureTable {
            urn
            type
            name
            description
            featureTableProperties {
                description
                mlFeatures {
                    urn
                }
                mlPrimaryKeys {
                    urn
                }
            }
            ownership {
                ...ownershipFields
            }
        }
        ... on MLModel {
            name
            description
            origin
            ownership {
                ...ownershipFields
            }
            platform {
                name
                info {
                    displayName
                    logoUrl
                }
            }
        }
        ... on MLModelGroup {
            name
            origin
            description
            ownership {
                ...ownershipFields
            }
            platform {
                name
                info {
                    displayName
                    logoUrl
                }
            }
        }
        ... on Tag {
            name
            description
        }
        ... on DataPlatform {
            name
            info {
                displayName
                logoUrl
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${NonRecursiveDataFlowFieldsFragmentDoc}
`;
