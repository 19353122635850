/* eslint-disable */
import * as Types from '../types.generated';

import {
    OwnershipFieldsFragment,
    GlobalTagsFieldsFragment,
    GlossaryTermsFragment,
    NonRecursiveDataFlowFieldsFragment,
} from './fragments.generated';
import { gql } from '@apollo/client';
import {
    OwnershipFieldsFragmentDoc,
    GlobalTagsFieldsFragmentDoc,
    GlossaryTermsFragmentDoc,
    NonRecursiveDataFlowFieldsFragmentDoc,
} from './fragments.generated';
import * as Apollo from '@apollo/client';
export type GetAutoCompleteResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteInput;
}>;

export type GetAutoCompleteResultsQuery = { __typename?: 'Query' } & {
    autoComplete?: Types.Maybe<
        { __typename?: 'AutoCompleteResults' } & Pick<Types.AutoCompleteResults, 'query' | 'suggestions'>
    >;
};

export type GetAutoCompleteMultipleResultsQueryVariables = Types.Exact<{
    input: Types.AutoCompleteMultipleInput;
}>;

export type GetAutoCompleteMultipleResultsQuery = { __typename?: 'Query' } & {
    autoCompleteForMultiple?: Types.Maybe<
        { __typename?: 'AutoCompleteMultipleResults' } & Pick<Types.AutoCompleteMultipleResults, 'query'> & {
                suggestions: Array<
                    { __typename?: 'AutoCompleteResultForEntity' } & Pick<
                        Types.AutoCompleteResultForEntity,
                        'type' | 'suggestions'
                    >
                >;
            }
    >;
};

export type SearchResultsFragment = { __typename?: 'SearchResults' } & Pick<
    Types.SearchResults,
    'start' | 'count' | 'total'
> & {
        searchResults: Array<
            { __typename?: 'SearchResult' } & {
                entity:
                    | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type' | 'tool' | 'chartId'> & {
                              properties?: Types.Maybe<
                                  { __typename?: 'ChartProperties' } & Pick<
                                      Types.ChartProperties,
                                      'name' | 'description' | 'externalUrl' | 'type' | 'access'
                                  > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                              glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                              editableProperties?: Types.Maybe<
                                  { __typename?: 'ChartEditableProperties' } & Pick<
                                      Types.ChartEditableProperties,
                                      'description'
                                  >
                              >;
                          })
                    | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'name' | 'urn' | 'type'> & {
                              info?: Types.Maybe<
                                  { __typename?: 'CorpGroupInfo' } & Pick<
                                      Types.CorpGroupInfo,
                                      'displayName' | 'description'
                                  >
                              >;
                              relationships?: Types.Maybe<
                                  { __typename?: 'EntityRelationshipsResult' } & {
                                      relationships: Array<
                                          { __typename?: 'EntityRelationship' } & Pick<
                                              Types.EntityRelationship,
                                              'type' | 'direction'
                                          > & {
                                                  entity:
                                                      | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                                      | ({ __typename?: 'CorpGroup' } & Pick<
                                                            Types.CorpGroup,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'CorpUser' } & Pick<
                                                            Types.CorpUser,
                                                            'username' | 'urn' | 'type'
                                                        > & {
                                                                info?: Types.Maybe<
                                                                    { __typename?: 'CorpUserInfo' } & Pick<
                                                                        Types.CorpUserInfo,
                                                                        | 'active'
                                                                        | 'displayName'
                                                                        | 'title'
                                                                        | 'firstName'
                                                                        | 'lastName'
                                                                    >
                                                                >;
                                                                editableInfo?: Types.Maybe<
                                                                    { __typename?: 'CorpUserEditableInfo' } & Pick<
                                                                        Types.CorpUserEditableInfo,
                                                                        'pictureLink'
                                                                    >
                                                                >;
                                                            })
                                                      | ({ __typename?: 'Dashboard' } & Pick<
                                                            Types.Dashboard,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'DataFlow' } & Pick<
                                                            Types.DataFlow,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'DataJob' } & Pick<
                                                            Types.DataJob,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'DataPlatform' } & Pick<
                                                            Types.DataPlatform,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'Dataset' } & Pick<
                                                            Types.Dataset,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'GlossaryTerm' } & Pick<
                                                            Types.GlossaryTerm,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'MLFeature' } & Pick<
                                                            Types.MlFeature,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'MLFeatureTable' } & Pick<
                                                            Types.MlFeatureTable,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'MLModel' } & Pick<
                                                            Types.MlModel,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'MLModelGroup' } & Pick<
                                                            Types.MlModelGroup,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'MLPrimaryKey' } & Pick<
                                                            Types.MlPrimaryKey,
                                                            'urn' | 'type'
                                                        >)
                                                      | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'urn' | 'type'>);
                                              }
                                      >;
                                  }
                              >;
                          })
                    | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'username' | 'urn' | 'type'> & {
                              info?: Types.Maybe<
                                  { __typename?: 'CorpUserInfo' } & Pick<
                                      Types.CorpUserInfo,
                                      'active' | 'displayName' | 'title' | 'firstName' | 'lastName' | 'fullName'
                                  >
                              >;
                              editableInfo?: Types.Maybe<
                                  { __typename?: 'CorpUserEditableInfo' } & Pick<
                                      Types.CorpUserEditableInfo,
                                      'pictureLink'
                                  >
                              >;
                          })
                    | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type' | 'tool' | 'dashboardId'> & {
                              properties?: Types.Maybe<
                                  { __typename?: 'DashboardProperties' } & Pick<
                                      Types.DashboardProperties,
                                      'name' | 'description' | 'externalUrl' | 'access'
                                  > & { lastModified: { __typename?: 'AuditStamp' } & Pick<Types.AuditStamp, 'time'> }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                              glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                              editableProperties?: Types.Maybe<
                                  { __typename?: 'DashboardEditableProperties' } & Pick<
                                      Types.DashboardEditableProperties,
                                      'description'
                                  >
                              >;
                          })
                    | ({ __typename?: 'DataFlow' } & Pick<
                          Types.DataFlow,
                          'urn' | 'type' | 'orchestrator' | 'flowId' | 'cluster'
                      > & {
                              properties?: Types.Maybe<
                                  { __typename?: 'DataFlowProperties' } & Pick<
                                      Types.DataFlowProperties,
                                      'name' | 'description' | 'project'
                                  >
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                              glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                              editableProperties?: Types.Maybe<
                                  { __typename?: 'DataFlowEditableProperties' } & Pick<
                                      Types.DataFlowEditableProperties,
                                      'description'
                                  >
                              >;
                          })
                    | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type' | 'jobId'> & {
                              dataFlow?: Types.Maybe<{ __typename?: 'DataFlow' } & NonRecursiveDataFlowFieldsFragment>;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              properties?: Types.Maybe<
                                  { __typename?: 'DataJobProperties' } & Pick<
                                      Types.DataJobProperties,
                                      'name' | 'description'
                                  >
                              >;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                              glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                              editableProperties?: Types.Maybe<
                                  { __typename?: 'DataJobEditableProperties' } & Pick<
                                      Types.DataJobEditableProperties,
                                      'description'
                                  >
                              >;
                          })
                    | ({ __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'urn' | 'type'>)
                    | ({ __typename?: 'Dataset' } & Pick<
                          Types.Dataset,
                          'name' | 'origin' | 'uri' | 'platformNativeType' | 'urn' | 'type'
                      > & {
                              platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>
                                      >;
                                  };
                              editableProperties?: Types.Maybe<
                                  { __typename?: 'DatasetEditableProperties' } & Pick<
                                      Types.DatasetEditableProperties,
                                      'description'
                                  >
                              >;
                              properties?: Types.Maybe<
                                  { __typename?: 'DatasetProperties' } & Pick<
                                      Types.DatasetProperties,
                                      'description'
                                  > & {
                                          customProperties?: Types.Maybe<
                                              Array<
                                                  { __typename?: 'StringMapEntry' } & Pick<
                                                      Types.StringMapEntry,
                                                      'key' | 'value'
                                                  >
                                              >
                                          >;
                                      }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              globalTags?: Types.Maybe<{ __typename?: 'GlobalTags' } & GlobalTagsFieldsFragment>;
                              glossaryTerms?: Types.Maybe<{ __typename?: 'GlossaryTerms' } & GlossaryTermsFragment>;
                              subTypes?: Types.Maybe<{ __typename?: 'SubTypes' } & Pick<Types.SubTypes, 'typeNames'>>;
                          })
                    | ({ __typename?: 'GlossaryTerm' } & Pick<Types.GlossaryTerm, 'name' | 'urn' | 'type'> & {
                              glossaryTermInfo?: Types.Maybe<
                                  { __typename?: 'GlossaryTermInfo' } & Pick<
                                      Types.GlossaryTermInfo,
                                      'definition' | 'termSource' | 'sourceRef' | 'sourceUrl'
                                  > & {
                                          customProperties?: Types.Maybe<
                                              Array<
                                                  { __typename?: 'StringMapEntry' } & Pick<
                                                      Types.StringMapEntry,
                                                      'key' | 'value'
                                                  >
                                              >
                                          >;
                                      }
                              >;
                          })
                    | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                    | ({ __typename?: 'MLFeatureTable' } & Pick<
                          Types.MlFeatureTable,
                          'urn' | 'type' | 'name' | 'description'
                      > & {
                              featureTableProperties?: Types.Maybe<
                                  { __typename?: 'MLFeatureTableProperties' } & Pick<
                                      Types.MlFeatureTableProperties,
                                      'description'
                                  > & {
                                          mlFeatures?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn'>
                                                  >
                                              >
                                          >;
                                          mlPrimaryKeys?: Types.Maybe<
                                              Array<
                                                  Types.Maybe<
                                                      { __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn'>
                                                  >
                                              >
                                          >;
                                      }
                              >;
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                          })
                    | ({ __typename?: 'MLModel' } & Pick<
                          Types.MlModel,
                          'name' | 'description' | 'origin' | 'urn' | 'type'
                      > & {
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>
                                      >;
                                  };
                          })
                    | ({ __typename?: 'MLModelGroup' } & Pick<
                          Types.MlModelGroup,
                          'name' | 'origin' | 'description' | 'urn' | 'type'
                      > & {
                              ownership?: Types.Maybe<{ __typename?: 'Ownership' } & OwnershipFieldsFragment>;
                              platform: { __typename?: 'DataPlatform' } & Pick<Types.DataPlatform, 'name'> & {
                                      info?: Types.Maybe<
                                          { __typename?: 'DataPlatformInfo' } & Pick<Types.DataPlatformInfo, 'logoUrl'>
                                      >;
                                  };
                          })
                    | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                    | ({ __typename?: 'Tag' } & Pick<Types.Tag, 'name' | 'description' | 'urn' | 'type'>);
                matchedFields: Array<{ __typename?: 'MatchedField' } & Pick<Types.MatchedField, 'name' | 'value'>>;
                insights?: Types.Maybe<
                    Array<{ __typename?: 'SearchInsight' } & Pick<Types.SearchInsight, 'text' | 'icon'>>
                >;
            }
        >;
        facets?: Types.Maybe<
            Array<
                { __typename?: 'FacetMetadata' } & Pick<Types.FacetMetadata, 'field' | 'displayName'> & {
                        aggregations: Array<
                            { __typename?: 'AggregationMetadata' } & Pick<
                                Types.AggregationMetadata,
                                'value' | 'count'
                            > & {
                                    entity?: Types.Maybe<
                                        | ({ __typename?: 'Chart' } & Pick<Types.Chart, 'urn' | 'type'>)
                                        | ({ __typename?: 'CorpGroup' } & Pick<Types.CorpGroup, 'urn' | 'type'>)
                                        | ({ __typename?: 'CorpUser' } & Pick<Types.CorpUser, 'urn' | 'type'>)
                                        | ({ __typename?: 'Dashboard' } & Pick<Types.Dashboard, 'urn' | 'type'>)
                                        | ({ __typename?: 'DataFlow' } & Pick<Types.DataFlow, 'urn' | 'type'>)
                                        | ({ __typename?: 'DataJob' } & Pick<Types.DataJob, 'urn' | 'type'>)
                                        | ({ __typename?: 'DataPlatform' } & Pick<
                                              Types.DataPlatform,
                                              'name' | 'displayName' | 'urn' | 'type'
                                          > & {
                                                  info?: Types.Maybe<
                                                      { __typename?: 'DataPlatformInfo' } & Pick<
                                                          Types.DataPlatformInfo,
                                                          'displayName' | 'logoUrl'
                                                      >
                                                  >;
                                              })
                                        | ({ __typename?: 'Dataset' } & Pick<Types.Dataset, 'urn' | 'type'>)
                                        | ({ __typename?: 'GlossaryTerm' } & Pick<
                                              Types.GlossaryTerm,
                                              'name' | 'urn' | 'type'
                                          >)
                                        | ({ __typename?: 'MLFeature' } & Pick<Types.MlFeature, 'urn' | 'type'>)
                                        | ({ __typename?: 'MLFeatureTable' } & Pick<
                                              Types.MlFeatureTable,
                                              'urn' | 'type'
                                          >)
                                        | ({ __typename?: 'MLModel' } & Pick<Types.MlModel, 'urn' | 'type'>)
                                        | ({ __typename?: 'MLModelGroup' } & Pick<Types.MlModelGroup, 'urn' | 'type'>)
                                        | ({ __typename?: 'MLPrimaryKey' } & Pick<Types.MlPrimaryKey, 'urn' | 'type'>)
                                        | ({ __typename?: 'Tag' } & Pick<
                                              Types.Tag,
                                              'name' | 'description' | 'urn' | 'type'
                                          >)
                                    >;
                                }
                        >;
                    }
            >
        >;
    };

export type GetSearchResultsQueryVariables = Types.Exact<{
    input: Types.SearchInput;
}>;

export type GetSearchResultsQuery = { __typename?: 'Query' } & {
    search?: Types.Maybe<{ __typename?: 'SearchResults' } & SearchResultsFragment>;
};

export type GetSearchResultsForMultipleQueryVariables = Types.Exact<{
    input: Types.SearchAcrossEntitiesInput;
}>;

export type GetSearchResultsForMultipleQuery = { __typename?: 'Query' } & {
    searchAcrossEntities?: Types.Maybe<{ __typename?: 'SearchResults' } & SearchResultsFragment>;
};

export const SearchResultsFragmentDoc = gql`
    fragment searchResults on SearchResults {
        start
        count
        total
        searchResults {
            entity {
                urn
                type
                ... on Dataset {
                    name
                    origin
                    uri
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                    editableProperties {
                        description
                    }
                    platformNativeType
                    properties {
                        description
                        customProperties {
                            key
                            value
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    subTypes {
                        typeNames
                    }
                }
                ... on CorpUser {
                    username
                    info {
                        active
                        displayName
                        title
                        firstName
                        lastName
                        fullName
                    }
                    editableInfo {
                        pictureLink
                    }
                }
                ... on CorpGroup {
                    name
                    info {
                        displayName
                        description
                    }
                    relationships(input: { types: ["IsMemberOfGroup"], direction: INCOMING }) {
                        relationships {
                            type
                            direction
                            entity {
                                urn
                                type
                                ... on CorpUser {
                                    username
                                    info {
                                        active
                                        displayName
                                        title
                                        firstName
                                        lastName
                                    }
                                    editableInfo {
                                        pictureLink
                                    }
                                }
                            }
                        }
                    }
                }
                ... on Dashboard {
                    urn
                    type
                    tool
                    dashboardId
                    properties {
                        name
                        description
                        externalUrl
                        access
                        lastModified {
                            time
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    editableProperties {
                        description
                    }
                }
                ... on Chart {
                    urn
                    type
                    tool
                    chartId
                    properties {
                        name
                        description
                        externalUrl
                        type
                        access
                        lastModified {
                            time
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    editableProperties {
                        description
                    }
                }
                ... on DataFlow {
                    urn
                    type
                    orchestrator
                    flowId
                    cluster
                    properties {
                        name
                        description
                        project
                    }
                    ownership {
                        ...ownershipFields
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    editableProperties {
                        description
                    }
                }
                ... on DataJob {
                    urn
                    type
                    dataFlow {
                        ...nonRecursiveDataFlowFields
                    }
                    jobId
                    ownership {
                        ...ownershipFields
                    }
                    properties {
                        name
                        description
                    }
                    globalTags {
                        ...globalTagsFields
                    }
                    glossaryTerms {
                        ...glossaryTerms
                    }
                    editableProperties {
                        description
                    }
                }
                ... on GlossaryTerm {
                    name
                    glossaryTermInfo {
                        definition
                        termSource
                        sourceRef
                        sourceUrl
                        customProperties {
                            key
                            value
                        }
                    }
                }
                ... on MLFeatureTable {
                    urn
                    type
                    name
                    description
                    featureTableProperties {
                        description
                        mlFeatures {
                            urn
                        }
                        mlPrimaryKeys {
                            urn
                        }
                    }
                    ownership {
                        ...ownershipFields
                    }
                }
                ... on MLModel {
                    name
                    description
                    origin
                    ownership {
                        ...ownershipFields
                    }
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                }
                ... on MLModelGroup {
                    name
                    origin
                    description
                    ownership {
                        ...ownershipFields
                    }
                    platform {
                        name
                        info {
                            logoUrl
                        }
                    }
                }
                ... on Tag {
                    name
                    description
                }
            }
            matchedFields {
                name
                value
            }
            insights {
                text
                icon
            }
        }
        facets {
            field
            displayName
            aggregations {
                value
                count
                entity {
                    urn
                    type
                    ... on Tag {
                        name
                        description
                    }
                    ... on GlossaryTerm {
                        name
                    }
                    ... on DataPlatform {
                        name
                        displayName
                        info {
                            displayName
                            logoUrl
                        }
                    }
                }
            }
        }
    }
    ${OwnershipFieldsFragmentDoc}
    ${GlobalTagsFieldsFragmentDoc}
    ${GlossaryTermsFragmentDoc}
    ${NonRecursiveDataFlowFieldsFragmentDoc}
`;
export const GetAutoCompleteResultsDocument = gql`
    query getAutoCompleteResults($input: AutoCompleteInput!) {
        autoComplete(input: $input) {
            query
            suggestions
        }
    }
`;

/**
 * __useGetAutoCompleteResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetAutoCompleteResultsQuery, GetAutoCompleteResultsQueryVariables>(
        GetAutoCompleteResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsQuery>;
export type GetAutoCompleteResultsLazyQueryHookResult = ReturnType<typeof useGetAutoCompleteResultsLazyQuery>;
export type GetAutoCompleteResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteResultsQuery,
    GetAutoCompleteResultsQueryVariables
>;
export const GetAutoCompleteMultipleResultsDocument = gql`
    query getAutoCompleteMultipleResults($input: AutoCompleteMultipleInput!) {
        autoCompleteForMultiple(input: $input) {
            query
            suggestions {
                type
                suggestions
            }
        }
    }
`;

/**
 * __useGetAutoCompleteMultipleResultsQuery__
 *
 * To run a query within a React component, call `useGetAutoCompleteMultipleResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAutoCompleteMultipleResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAutoCompleteMultipleResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetAutoCompleteMultipleResultsQuery(
    baseOptions: Apollo.QueryHookOptions<
        GetAutoCompleteMultipleResultsQuery,
        GetAutoCompleteMultipleResultsQueryVariables
    >,
) {
    return Apollo.useQuery<GetAutoCompleteMultipleResultsQuery, GetAutoCompleteMultipleResultsQueryVariables>(
        GetAutoCompleteMultipleResultsDocument,
        baseOptions,
    );
}
export function useGetAutoCompleteMultipleResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetAutoCompleteMultipleResultsQuery,
        GetAutoCompleteMultipleResultsQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetAutoCompleteMultipleResultsQuery, GetAutoCompleteMultipleResultsQueryVariables>(
        GetAutoCompleteMultipleResultsDocument,
        baseOptions,
    );
}
export type GetAutoCompleteMultipleResultsQueryHookResult = ReturnType<typeof useGetAutoCompleteMultipleResultsQuery>;
export type GetAutoCompleteMultipleResultsLazyQueryHookResult = ReturnType<
    typeof useGetAutoCompleteMultipleResultsLazyQuery
>;
export type GetAutoCompleteMultipleResultsQueryResult = Apollo.QueryResult<
    GetAutoCompleteMultipleResultsQuery,
    GetAutoCompleteMultipleResultsQueryVariables
>;
export const GetSearchResultsDocument = gql`
    query getSearchResults($input: SearchInput!) {
        search(input: $input) {
            ...searchResults
        }
    }
    ${SearchResultsFragmentDoc}
`;

/**
 * __useGetSearchResultsQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchResultsQuery(
    baseOptions: Apollo.QueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export function useGetSearchResultsLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<GetSearchResultsQuery, GetSearchResultsQueryVariables>,
) {
    return Apollo.useLazyQuery<GetSearchResultsQuery, GetSearchResultsQueryVariables>(
        GetSearchResultsDocument,
        baseOptions,
    );
}
export type GetSearchResultsQueryHookResult = ReturnType<typeof useGetSearchResultsQuery>;
export type GetSearchResultsLazyQueryHookResult = ReturnType<typeof useGetSearchResultsLazyQuery>;
export type GetSearchResultsQueryResult = Apollo.QueryResult<GetSearchResultsQuery, GetSearchResultsQueryVariables>;
export const GetSearchResultsForMultipleDocument = gql`
    query getSearchResultsForMultiple($input: SearchAcrossEntitiesInput!) {
        searchAcrossEntities(input: $input) {
            ...searchResults
        }
    }
    ${SearchResultsFragmentDoc}
`;

/**
 * __useGetSearchResultsForMultipleQuery__
 *
 * To run a query within a React component, call `useGetSearchResultsForMultipleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSearchResultsForMultipleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSearchResultsForMultipleQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetSearchResultsForMultipleQuery(
    baseOptions: Apollo.QueryHookOptions<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>,
) {
    return Apollo.useQuery<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>(
        GetSearchResultsForMultipleDocument,
        baseOptions,
    );
}
export function useGetSearchResultsForMultipleLazyQuery(
    baseOptions?: Apollo.LazyQueryHookOptions<
        GetSearchResultsForMultipleQuery,
        GetSearchResultsForMultipleQueryVariables
    >,
) {
    return Apollo.useLazyQuery<GetSearchResultsForMultipleQuery, GetSearchResultsForMultipleQueryVariables>(
        GetSearchResultsForMultipleDocument,
        baseOptions,
    );
}
export type GetSearchResultsForMultipleQueryHookResult = ReturnType<typeof useGetSearchResultsForMultipleQuery>;
export type GetSearchResultsForMultipleLazyQueryHookResult = ReturnType<typeof useGetSearchResultsForMultipleLazyQuery>;
export type GetSearchResultsForMultipleQueryResult = Apollo.QueryResult<
    GetSearchResultsForMultipleQuery,
    GetSearchResultsForMultipleQueryVariables
>;
